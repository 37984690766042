import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import SanjeeviniSrushti from "../../../components/developers/sanjeevinideveloper/ourproperties/SanjeeviniSrushti";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const SowparnikaSrushtiPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <SanjeeviniSrushti />
    </>
  )
}

export default SowparnikaSrushtiPage
